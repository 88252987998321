import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Button } from 'antd'

import SEO from 'components/seo'
import HtmlContent from 'components/HtmlContent'

import styles from './page.module.less'

const PageTemplate = ({ data, pageContext }) => {
  const setDefault = () => {
    document.querySelector('#tab1').removeAttribute('checked')
    document.querySelector('#tab2').removeAttribute('checked')
    document.querySelector('#tab3').removeAttribute('checked')
    document.querySelector('#tab4').removeAttribute('checked')
    document.querySelector('#tab5').removeAttribute('checked')
  }
  useEffect(() => {
    const zhls = ['case-lotus'] // tab1
    const zhwd = ['bank', 'store'] // tab2
    const zhxy = ['case-wenli'] // tab3
    const zhyq = ['case-ningbo', 'telecom', 'case-jiaoda', 'case-zhongxing'] // tab4
    const ansc = [
      'case-zhongjian',
      'case-zhongxin',
      'case-longjing',
      'case-pig'
    ] // tab5
    const path = window.document.location.href
    const arr = path.split('#')
    if (arr.length > 1) {
      setDefault()
      if (zhls.includes(arr[1])) {
        document.querySelector('#tab1').setAttribute('checked', true)
      }
      if (zhwd.includes(arr[1])) {
        document.querySelector('#tab2').setAttribute('checked', true)
      }
      if (zhxy.includes(arr[1])) {
        document.querySelector('#tab3').setAttribute('checked', true)
      }
      if (zhyq.includes(arr[1])) {
        document.querySelector('#tab4').setAttribute('checked', true)
      }
      if (ansc.includes(arr[1])) {
        document.querySelector('#tab5').setAttribute('checked', true)
      }
    }
  }, [])
  const {
    page: { frontmatter, excerpt, html }
  } = data
  const { pageKey } = pageContext
  const desktopBg =
    frontmatter.featuredImage &&
    frontmatter.featuredImage.childImageSharp.fluid.src
  const mobileBg =
    frontmatter.featuredImageMobile &&
    frontmatter.featuredImageMobile.childImageSharp.fluid.src

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description || excerpt}
      />
      <div className={styles[`page_${pageKey}`]}>
        {(desktopBg || mobileBg) && (
          <div
            className={styles.pageHero}
            css={{
              backgroundImage: `url(${mobileBg || desktopBg})`,
              '@media (min-width: 768px)': {
                backgroundImage: `url(${desktopBg})`
              }
            }}
          >
            <div className="container">
              <div className={styles.pageTitle}>
                <h1>{frontmatter.title}</h1>
                <h2>{frontmatter.subtitle}</h2>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.description
                  }}
                />
                {frontmatter.linkUrl && (
                  <a href={frontmatter.linkUrl} rel="noopener noreferrer">
                    <Button ghost>{frontmatter.linkText}</Button>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        <section className={styles.pageContent}>
          <HtmlContent html={html} />
        </section>
      </div>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageMobile {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        linkText
        linkUrl
      }
    }
  }
`
